(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'ManageAddressesCtrl';

    angular.module('ecog.manageaddresses')
        .controller(controllerId, ['$log', '$rootScope', '$modal', '$http', 'ACTION_URL', 'usersService', 'userCheckService', manage]);

    function manage($log, $rootScope, $modal, $http, ACTION_URL, usersService, userCheckService) {

        var vm = this;

        vm.isSubmitting = false;
        vm.errors = [];
        vm.successMessage = null;
        vm.page = 1;

        vm.addresses = [];
        vm.countries = [];

        vm.createAddress = createAddress;
        vm.editAddress = editAddress;
        vm.removeAddress = removeAddress;
        vm.makedefault = makedefault;

        vm.cancel = cancel;
        vm.save = save;
        vm.remove = remove;

        init();

        function createAddress(type) {
          vm.errors = [];
          vm.successMessage = null;
          vm.address = {};
          vm.address.type = type;
          vm.creatingAddress = true;
          vm.page = 2;
        }

        function editAddress(address) {
          vm.errors = [];
          vm.successMessage = null;
          vm.address = address;

          vm.creatingAddress = false;
          vm.page = 2;
        }

        function removeAddress(address) {
          vm.errors = [];
          vm.successMessage = null;
          vm.address = address;
          vm.creatingAddress = false;
          vm.page = 3;
        }

        function cancel() {
          vm.errors = [];
          vm.successMessage = null;
          vm.page = 1;
        }

        function save() {
          $http.post(ACTION_URL + "saveaddress.php", vm.address).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              init();
              vm.successMessage = "Address saved successfully";
              vm.page = 1;
            } else if (data.data.errors) {
              vm.errors = data.data.errors;
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }
          }, function (error) {
            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
          });
        }

        function remove() {
          $http.post(ACTION_URL + "removeaddress.php", vm.address).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              init();
              vm.successMessage = "Address removed successfully";
              vm.page = 1;
            } else if (data.data.errors) {
              vm.errors = data.data.errors;
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }
          }, function (error) {
            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
          });
        }

        function makedefault(address) {
          vm.successMessage = "";
          $http.post(ACTION_URL + "makedefaultaddress.php", address).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              userCheckService.check();
              init();
              vm.successMessage = "Default address has been changed successfully";
            } else if (data.data.errors) {
              vm.errors = data.data.errors;
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }
          }, function (error) {
            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
          });
        }

        function init() {
          // reload addresses..
          vm.loadingAddresses = true;

          usersService.addresses().then(function (addresses) {
            vm.addresses = addresses;
            angular.forEach(vm.addresses, function (value, key) {

              var longname = value.address1;
              longname += value.address2 && value.address2.length > 0 ? ", " + value.address2 : "";
              longname += value.city && value.city.length > 0 ? ", " + value.city : "";
              longname += value.county && value.county.length > 0 ? ", " + value.county : "";
              longname += value.postcode && value.postcode.length > 0 ? ", " + value.postcode : "";
              longname += value.country && value.country.length > 0 ? ", " + value.country : "";
              value.longname = longname;

            });
            vm.loadingAddresses = false;
          });

          usersService.countries().then(function (countries) {
            vm.countries = countries;
          });

        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/manageaddresses/manageaddresses.js","/components/manageaddresses")