(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('specificProductsFilter', specificProductsFilter);

  specificProductsFilter.$inject = ['$log'];

  function specificProductsFilter($log) {

    return function(items, ids) {
      var out = [];

      if (angular.isArray(items) && angular.isArray(ids)) {
        items.forEach(function(item) {
          if (ids.indexOf(item.id) !== -1) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/specificProductsFilter.js","/shared/filters")