(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.categories')
    .factory('categoriesService', categoriesService);

  categoriesService.$inject = ['$log', '$http', 'ACTION_URL', '$q'];

  function categoriesService ($log, $http, ACTION_URL, $q) {

    var service = {
      get: get,
      getById: getById
    };

    return service;

    ////////////

    function get() {
      /*return [
        {
          id: 1,
          name: 'Pure Range Dog Treats',
          img: 'img/pure-duck.jpg',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Award winning, 100 percent pure meat treats for dogs.',
          desc: '<p>A range of naturally pure treats in eight tasty irresistible varieties: <strong>Kangaroo</strong>, <strong>venison</strong>, <strong>chicken</strong>, <strong>duck</strong>, <strong>salmon</strong>, <strong>beef</strong>, <strong>rabbit</strong> and <strong>lamb</strong>. Can be used whole or broken into pieces for smaller treats.</p><ul><li>Simply 100% pure fresh meat...that’s it</li><li>Grain and gluten free. No additives, no preservatives....nothing!</li><li>Healthy and nutritious treats for all dogs</li></ul>'
        },
        {
          id: 2,
          name: 'NatuTripe',
          img: 'img/natutripe2.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: '100 percent pure tripe pressed in to the shape of a bone.',
          desc: '<p>Simply 100% pure tripe pressed in to the shape of a bone </p><ul><li>High protein</li><li>Cares for teeth and gums</li><li>Suitable as a treat, reward or training aid.</li></ul>'
        },
        {
          id: 3,
          name: 'Tripe Filled Rawhide Bones',
          img: 'img/tripe-filled-rawhide-bone.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: '100 percent pure tripe inside a rawhide skin.',
          desc: '<p>100% pure tripe inside a rawhide skin.</p><ul><li>The tripe filled bone is great between meals as a treat or training reward.</li><li>High in protein</li><li>Low fat</li></ul>'
        },
        {
          id: 4,
          name: 'Ears',
          img: 'img/cows-ears.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Dried animal ears for dog treats.'
        },
        {
          id: 5,
          name: 'Tails',
          img: 'img/lambs-tails.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Dried animal tails for dogs.'
        },
        {
          id: 6,
          name: 'Trotters, Feet and Hooves',
          img: 'img/trotters.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Dog treats.'
        },
        {
          id: 7,
          name: 'Pizzles',
          img: 'img/bulls-pizzles.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Naturally dried bulls pizzles for dogs.',
          desc: '<p>Air-dried to lock in the goodness. It’s worth noting that as our products are naturally dried (not cooked) the bone does not splinter.</p><ul><li>Air dried to perfection</li><li>Completely free of any bacteria</li><li>Long lasting and packed with flavour and goodness</li></ul>'
        },
        {
          id: 8,
          name: 'Horns',
          img: 'img/buffalo-horn.jpeg',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Long lasting horn dog treats.',
          desc: '<p>A long-lasting treat that wears down very slowly.</p><ul><li>Low in fat</li><li>Ideal for agressive chewers</li><li>Will not splinter</li></ul>'
        },
        {
          id: 9,
          name: 'Pork',
          img: 'img/porky-bites.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Pork based treats for dogs.'
        },
        {
          id: 10,
          name: 'Beef',
          img: 'img/smoked-cows-ears.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Beef based treats for dogs.'
        },
        {
          id: 11,
          name: 'Poultry',
          img: 'img/puffed-chicken-feet.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Poultry based treats for dogs.'
        },
        {
          id: 12,
          name: 'Lamb',
          img: 'img/lambs-trotters.png',
          highlightClass: 'dog-treat-highlight',
          className: 'dog',
          icon: 'img/icon-dog-small.png',
          shortdesc: 'Lamb based treats for dogs.'
        }
      ];*/
      var categories = [],
          defer = $q.defer();

      $http.post(ACTION_URL + "categories.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;

    }

    function getById(id) {

      var defer = $q.defer();

      $http.post(ACTION_URL + "category.php?id=" + id).success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.item);
        }
      });

      return defer.promise;
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/categories/categories.service.js","/components/categories")