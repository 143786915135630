(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    var core = angular.module('ecog.core');

    var config = {
        appErrorPrefix: '[Error] ', //Configure the exceptionHandler decorator
        appTitle: 'J R Pet Products - Wholesale',
        version: '1.0.0'
    };

    core.value('config', config);

    core.config(configure);

    /* @ngInject */
    function configure ($logProvider, $routeProvider, routehelperConfigProvider, $httpProvider) {
        // turn debugging off/on (no info or warn)
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }

        // Configure the common route provider
        routehelperConfigProvider.config.$routeProvider = $routeProvider;
        routehelperConfigProvider.config.docTitle = 'JR Pet Products - ';
        var resolveAlways = { /* @ngInject */
            //ready: function(dataservice) {
                //return dataservice.ready();
            //}
            // ready: ['dataservice', function (dataservice) {
            //    return dataservice.ready();
            // }]
        };
        routehelperConfigProvider.config.resolveAlways = resolveAlways;
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = true;
    }

    core.config(['lockerProvider', function config(lockerProvider) {
      lockerProvider.defaults({
          driver: 'local',
          namespace: 'jr-basket',
          separator: '.',
          eventsEnabled: true,
          extend: {}
      });
    }]);

    core.config(['uiMask.ConfigProvider', function config(uiMaskConfigProvider) {
      //uiMaskConfigProvider.modelViewValue(true);
    }]);
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/core/config.js","/core")