(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.products')
          .controller('ProductsCtrl', ProductsCtrl);

  ProductsCtrl.$inject = ['$log', '$scope', '$timeout', '$filter', '$rootScope', 'categories', 'category', 'products', 'productsService', 'search', 'searchtext', 'promotion', 'giveaway', '$interval', '$location'];

  function ProductsCtrl($log, $scope, $timeout, $filter, $rootScope, categories, category, products, productsService, search, searchtext, promotion, giveaway, $interval, $location) {

    var vm = this;

    vm.viewmethod = 1;

    vm.categories = categories;


    $rootScope.category = category;

    if ($rootScope.category && $rootScope.category.id !== 0) {
      var masterProducts = $filter('filter')($filter('categoryFilter')($rootScope.products, $rootScope.category.id), {'isMaster': true});
      angular.forEach(masterProducts, function (value) {
        var masterDone = false;
        angular.forEach(value.subProducts, function(subProduct) {
          // find the first product that matches the category...
          if (subProduct.categories.indexOf($rootScope.category.id.toString()) !== -1) {
            if (!masterDone) {
              masterDone = true;
              if (value.selectedSub !== subProduct.id) {
                // needs changing...
                $rootScope.cpChangeSelection(value, subProduct.id);

              }
            }
            subProduct.categoryHide = false;

          } else {
            subProduct.categoryHide = true;
          }
        });
      });
    } else if ($rootScope.category && $rootScope.category.id === 0) {
      var masterProducts = $filter('filter')($rootScope.products, {'isMaster': true});
      angular.forEach(masterProducts, function (value) {
        var masterDone = false;
        angular.forEach(value.subProducts, function(subProduct) {
          subProduct.categoryHide = false;
          if (!masterDone) {
            masterDone = true;
            if (value.selectedSub !== subProduct.id) {
              // needs changing...
              $rootScope.cpChangeSelection(value, subProduct.id);
            }
          }
        });
      });
    }

    vm.promotion = {};
    vm.promotionId = promotion;


    if (promotion !== undefined) {
      productsService.getPromotion(promotion)
        .then(function (data) {
          vm.promotion = data;

        });
    }


    vm.search = (search !== undefined && search === true) ? true : false;
    vm.searchtext = searchtext !== undefined ? searchtext : '';
    vm.clickBlackFriday = clickBlackFriday;
    vm.goSearch = goSearch;

    vm.products = search ? [] : $rootScope.products;

    vm.addToOrder = function (product) {

      var item = angular.extend({}, product);
      item.name = $rootScope.pricelist[product.code].name;
      item.price = ($rootScope.pricelist[product.code].promotion !== false && $rootScope.pricelist[product.code].promotion.discount ? $rootScope.pricelist[product.code].promotion.price : $rootScope.pricelist[product.code].price);
      item.size = $rootScope.pricelist[product.code].uom;
      if ($rootScope.basket[product.code] !== undefined) {
        $rootScope.basket[product.code].qty += item.qty;

        if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
          $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
        }
      } else {
        $rootScope.basket[product.code] = item;

        if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
          $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
        }
      }
      product.qty = 1;
      product.adding = true;
      $timeout(function() {
        product.added = true;
        product.adding = false;
        $window.ga('send', 'event', 'Basket', 'click', 'Added ' + item.qty + ' of ' + product.code + ' ' + product.name + ' to basket');
      }, 3000);
    };

    vm.viewProduct = function (product) {
      productsService.productModal(product);
    }

    vm.viewMaster = function (product) {
      angular.forEach($rootScope.products, function (value) {
        if (product.masterid === value.id) {
          $rootScope.cpChangeSelection(value, product.id);
          productsService.productModal(value);
        }
      });
    }

    function clickBlackFriday() {
      if ($rootScope.currentUser && $rootScope.currentUser.type == 1) {
        $location.path('/promotions');
      } else {
        $location.path('/register');
      }
    }

    function goSearch () {
      $location.path('/search/'+vm.searchtext);
      vm.searchtext = "";
    }

    if (search) {
      vm.searchLoading = true;

      productsService.query(vm.searchtext)
        .then(function (data) {
          vm.searchLoading = false;
          vm.products = data;
          vm.searchedFor = vm.searchtext;
        });
    }

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/products/products.js","/components/products")