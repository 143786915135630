(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'CheckoutReturnCtrl';

    angular.module('ecog.checkout')
        .controller(controllerId, ['$log', '$scope', '$rootScope', checkoutreturn]);

    function checkoutreturn($log, $scope, $rootScope) {

        var vm = this;

        vm.stage = 5;
        $rootScope.basket = {};

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/checkout/checkoutreturn.js","/components/checkout")