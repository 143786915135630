(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('tradingforFilter', tradingforFilter);

  tradingforFilter.$inject = ['$log'];

  function tradingforFilter() {
    return function(type, types) {
      var out = "";

      if (angular.isArray(types)) {
        types.forEach(function(checktype) {
          if (type === checktype.id) {
            out = checktype.name;
          }
        });
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/tradingforFilter.js","/shared/filters")