(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.layout')
        .controller('NavBar', NavBar);

    NavBar.$inject = ['$route', '$scope', '$rootScope', '$log', '$location', 'searchService'];

    function NavBar($route, $scope, $rootScope, $log, $location, searchService) {
        /*jshint validthis: true */
        var vm = this;

        vm.goSearch = goSearch;
        vm.openSearch = openSearch;

        // functions
        function goSearch () {
          $location.path('/search/'+vm.searchtext);
          vm.searchtext = "";
        }

        function openSearch() {
          searchService.search();
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/layout/navbar.js","/shared/layout")