(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'RegisterCtrl';

    angular.module('ecog.register')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'usersService', 'loadingService', register]);

    function register($log, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, usersService, loadingService) {

        var vm = this;

        vm.isSubmitting = false;
        vm.errors = [];
        vm.stage = 1;
        vm.user = {};
        vm.mask = "0999999999?9";

        vm.user.website = '';

        vm.next = next;
        vm.back = back;
        vm.submit = submit;
        vm.useragreement = useragreement;
        vm.checkmask = checkmask;

        vm.countries = [];


        function next() {
          vm.stage++;
          vm.errors = [];
        }

        function back() {
          vm.stage--;
        }

        function submit() {
          vm.isSubmitting = true;
          loadingService.start("registerOverlay", "Submitting");
          if (vm.user.ukbased === 'true') {
            vm.user.country = 'GB';
          }

          $http.post(ACTION_URL + "register.php", vm.user).then(function(data, status) {
            vm.message = data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              vm.stage = 4;

            } else if (data.data.errors) {
              vm.errors = data.data.errors;
              if (data.data.errorstage !== undefined && data.data.errorstage !== null) {
                vm.stage = data.data.errorstage;
              }
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }

            vm.isSubmitting = false;
          }, function (error) {

            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
            loadingService.stop("registerOverlay");
          });
        }

        function useragreement() {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'views/components/misc/useragreement.html?update=201702101030&ek=2',
            controller: 'UserAgreementModalCtrl',
            controllerAs: 'vm',
            size: 'md'
          });

          modalInstance.result.then(function () {
            vm.user.agreement = true;
          }, function () {

          });
        }

        function checkmask() {

        }

        init();


        //

        function init() {
          usersService.countries().then(function(data) {
            vm.countries = data;
          });
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/register/register.js","/components/register")