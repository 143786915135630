(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'ProductModalCtrl';

    angular.module('ecog.products')
        .controller(controllerId, ['$log', '$rootScope', '$modalInstance', 'item', '$timeout', '$window', '$location', product]);

    function product($log, $rootScope, $modalInstance, item, $timeout, $window, $location) {
      var vm = this;

      vm.product = item;

      vm.close = close;

      function close() {
        $modalInstance.dismiss();
      }

      $window.ga('send', 'pageview', { page: $location.path() + '/' + vm.product.code });

      vm.addToOrder = function (product) {

        var newitem = angular.extend({}, product);
        newitem.name = $rootScope.pricelist[product.code].name;
        newitem.price = ($rootScope.pricelist[product.code].promotion !== false && $rootScope.pricelist[product.code].promotion.discount ? $rootScope.pricelist[product.code].promotion.price : $rootScope.pricelist[product.code].price);
        newitem.size = $rootScope.pricelist[product.code].uom;
        if ($rootScope.basket[product.code] !== undefined) {

          $rootScope.basket[product.code].qty += newitem.qty;

          if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
            $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
          }
        } else {
          $rootScope.basket[product.code] = newitem;
          if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
            $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
          }
        }
        product.qty = 1;
        product.adding = true;
        $timeout(function() {
          product.added = true;
          product.adding = false;

          $window.ga('send', 'event', 'Basket', 'click', 'Added ' + newitem.qty + ' of ' + product.code + ' ' + product.name + ' to basket');
        }, 3000);
      };

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/products/modal/product.js","/components/products/modal")