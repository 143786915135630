(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'PasswordCtrl';

    angular.module('ecog.password')
        .controller(controllerId, ['$log', '$rootScope', '$modal', '$http', 'ACTION_URL', password]);

    function password($log, $rootScope, $modal, $http, ACTION_URL) {

        var vm = this;

        vm.isSubmitting = false;
        vm.success = false;
        vm.errors = [];
        vm.user = {
        };

        vm.submit = submit;

        function submit() {
          $http.post(ACTION_URL + "password.php", vm.user).success(function(data, status) {
            if (data.message !== undefined && data.message === "SUCCESS") {
              vm.success = true;
            } else {
              vm.errors = data.errors;
            }
          });
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/password/password.js","/components/password")