(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog')
        .directive('mAppLoading', mAppLoading);

        mAppLoading.$inject = ['$animate', '$timeout'];

    /* @ngInject */
    function mAppLoading($animate, $timeout) {
        var directive = {
          link: linkFunc,
          restrict: "C"
        };

        return directive;

        function linkFunc( scope, element, attributes ) {
            // Due to the way AngularJS prevents animation during the bootstrap
            // of the application, we can't animate the top-level container; but,
            // since we added "ngAnimateChildren", we can animated the inner
            // container during this phase.
            // --
            // NOTE: Am using .eq(1) so that we don't animate the Style block.
            $timeout(function () {

              try {
                $animate.leave( element.children().eq( 1 ) ).then(
                    function cleanupAfterAnimation() {
                        // Remove the root directive element.
                        element.remove();
                        // Clear the closed-over variable references.
                        scope = element = attributes = null;
                    }
                );
              } catch (e) {
                // do nothing
              }
            }, 2000);

        }
    }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/directives/loadingScreen.js","/shared/directives")