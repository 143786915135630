(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'LoginCtrl';

    angular.module('ecog.login')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', 'ACTION_URL', '$http', 'productsService', 'loadingService', login]);

    function login($log, $rootScope, $location, $cookies, ACTION_URL, $http, productsService, loadingService) {
        var vm = this;

        vm.isSubmitting = false;
        vm.errors = [];
        vm.submit = submit;
        vm.user = {
          email: '',
          password: ''
        }

        if ($cookies.get('email')) {
          vm.user.email = $cookies.get('email');
          vm.rememberme = true;
        }



        function submit(user) {
          if (!vm.submitting) {
            vm.isSubmitting = true;

            loadingService.start("signinOverlay", "Signing in");

            $http.post(ACTION_URL + "login.php", vm.user).then(function(data, status) {
              vm.message = data.data;

              if (data.data.message !== undefined && data.data.message === "SUCCESS") {
                vm.loginLoading = true;
                $rootScope.currentUser = data.data.userObj;
                $rootScope.pricelist = data.data.priceObj;
                $rootScope.orderLimit = data.data.userObj.type === "1" || data.data.userObj.type === 1 ? 250.00 : 1000.00;
                $location.path('/');
                if (vm.rememberme === true) {
                  $cookies.put('email', vm.user.email);
                } else {
                  $cookies.remove('email');
                }
                vm.isSubmitting = false;
                vm.errors = [];

                // reload products
                productsService.get()
                  .then(function (value) {
                    $rootScope.products = value;
                  });

                productsService.getFrequent()
                  .then(function (value) {
                    $rootScope.frequent = value;
                    $rootScope.fetchedfrequentproducts = true;
                  });
              } else if (data.data.errors) {
                vm.isSubmitting = false;
                vm.errors = data.data.errors;
              } else {
                vm.isSubmitting = false;
                vm.errors = [ 'There was an unforseen error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.<br>' + data.data.message ];
              }
            }, function (error) {

              vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
            }).finally(function () {
              vm.isSubmitting = false;
              loadingService.stop("signinOverlay");
            });
          }
        }
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/login/login.js","/components/login")