(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular.module('ecog.core', [
        /*[, ]
         * Angular modules
         */
        'ui.bootstrap', 'ui.mask', 'ngAnimate', 'ngRoute', 'ngCookies', 'ngSanitize', 'ngMessages', 'bsLoadingOverlay', 'bsLoadingOverlaySpinJs',
        /*
         * Our reusable cross app code modules
         */
        'common.router',
        /*
         * 3rd Party modules
         */
         'angular-locker'
    ]);
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/core/core.module.js","/core")