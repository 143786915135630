(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'AccountCtrl';

    angular.module('ecog.account')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'accountService', account]);

    function account($log, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, accountService) {

        var vm = this;

        vm.orders = [];
        vm.paymenttypes = [ {id:1,name:'Pay-Pal'},{id:2,name:'Direct Bank Transfer'},{id:3,name:'On Account'}];
        vm.paymentstatus = [ {id:1,name:'Awaiting Transaction Reconcile'},{id:2,name:'Awaiting Payment'},{id:3,name:'Awaiting Processing'},{id:4,name:'Processing'},{id:5,name:'Partially Dispatched'},{id:6,name:'Dispatched'},{id:7,name:'Cancelled'}];

        vm.viewOrder = function (id) {
          $location.path('/order/' + id);
        }

        accountService.getOrders().then(function (data) {
          angular.forEach(data, function (value) {
            value.dateplaced = new Date(value.dateplaced);
          });
          vm.orders = data;
        });




    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/account/account.js","/components/account")