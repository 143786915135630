(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.barcodes')
          .controller('BarcodesCtrl', BarcodesCtrl);

  BarcodesCtrl.$inject = ['$scope', 'products', 'categories', '$window', 'ACTION_URL'];

  function BarcodesCtrl($scope, products, categories, $window, ACTION_URL) {

    var vm = this;

    vm.products = products;
    vm.categories = categories;

    //

    vm.barcodePdf = barcodePdf;
    vm.barcodeCsv = barcodeCsv;

    //

    function barcodePdf () {
      $window.open(ACTION_URL + "barcode.php?pdf=1");
    };

    function barcodeCsv () {
      $window.open(ACTION_URL + "barcode.php?csv=1");
    };

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/barcodes/barcodes.js","/components/barcodes")