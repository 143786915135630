(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/*

  Really basic filter that adds classes in a staggered way

  input: items, boolField, milliseconds

*/
(function(){

  angular.module('ecog')
    .filter('staggeredClassFilter', staggeredClassFilter);

  staggeredClassFilter.$inject = ['$log', '$timeout'];

  function staggeredClassFilter($log, $timeout) {

    return function(items, boolField, milliseconds, delay) {
      if (delay === undefined) {
        delay = 0;
      }
      if (angular.isArray(items)) {
        var timeout = milliseconds + delay;
        items.forEach(function(item) {
          if (item[boolField] !== true) {
            $timeout(function() {
              item[boolField] = true;
            }, timeout);
            timeout += milliseconds;
          }
        });
      }
      return items;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/staggeredClassFilter.js","/shared/filters")