(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('activePromotions', activePromotions);

  activePromotions.$inject = ['$log'];

  function activePromotions($log) {

    return function(items, active) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function(item) {
          if (active === false) {
            if (item.upcoming === true) {
              out.push(item);
            }
          } else if (active && !item.upcoming) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/activePromotions.js","/shared/filters")