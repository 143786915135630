(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.search')
    .factory('searchService', searchService);

  searchService.$inject = ['$log', '$modal', '$http', 'ACTION_URL', '$q'];

  function searchService ($log, $modal, $http, ACTION_URL, $q) {

    var service = {
      search: search
    };

    return service;

    ////////////
    function search() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/search/search.html?update=' + FILEDATES['views/components/search/search.html'] || '',
        controller: 'SearchModalCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {}
      });

      modalInstance.result.then(function (item) {

      }, function () {

      });
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/search/search.service.js","/components/search")