(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.contact')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    function getRoutes() {
        return [
            {
                url: '/contact',
                config: {
                    templateUrl: 'views/components/contact/contact.html?update=' + FILEDATES['views/components/contact/contact.html'] || '',
                    controller: 'ContactCtrl',
                    controllerAs: 'vm',
                    title: 'Contact',
                    settings: {
                        nav: 3,
                        content: '<i class="icons icon-envelope"></i>Contact us',
                        requireAuth: false
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/contact/config.route.js","/components/contact")