(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog')
    .factory('settingsService', settingsService);

  settingsService.$inject = ['$log', '$http', 'ACTION_URL', '$q'];

  function settingsService ($log, $http, ACTION_URL, $q) {

    var service = {
      getPaypal: getPaypal
    };

    return service;

    ////////////

    function getPaypal() {

      var defer = $q.defer();

      $http.post(ACTION_URL + "paypal.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/misc/settings.service.js","/components/misc")