(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.checkout')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['usersService', 'settingsService'];

    function getRoutes() {
        return [
            {
                url: '/checkout',
                config: {
                    templateUrl: 'views/components/checkout/checkout.html?update=' + FILEDATES['views/components/checkout/checkout.html'] || '',
                    controller: 'CheckoutCtrl',
                    controllerAs: 'vm',
                    title: 'Checkout',
                    resolve: {
                      user: function (usersService) {
                        return usersService.get();
                      },
                      paypal: function (settingsService) {
                        return settingsService.getPaypal();
                      },
                      addresses: function (usersService) {
                        return usersService.addresses();
                      }
                    }
                }
            },
            {
                url: '/checkoutreturn',
                config: {
                    templateUrl: 'views/components/checkout/checkoutreturn.html?update=' + FILEDATES['views/components/checkout/checkoutreturn.html'] || '',
                    controller: 'CheckoutReturnCtrl',
                    controllerAs: 'vm',
                    title: 'Checkout',
                    resolve: {
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/checkout/config.route.js","/components/checkout")