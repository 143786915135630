(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function (){
  angular.module('ecog')
    .directive("compareTo", compareTo);

  compareTo.$inject = ['$log'];

  function compareTo($log) {

    return {
      require: "ngModel",
      scope: {
        otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {

        ngModel.$validators.compareTo = function(modelValue) {
          return modelValue === scope.otherModelValue.$modelValue;
        };

        scope.$watch("otherModelValue", function() {
          ngModel.$validate();
        });
      }
    };
  };

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/directives/compareTo.js","/shared/directives")