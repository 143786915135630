(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.pricelist')
          .controller('PricelistCtrl', PricelistCtrl);

  PricelistCtrl.$inject = ['$scope', '$timeout', '$rootScope', 'products', 'categories', 'productsService'];

  function PricelistCtrl($scope, $timeout, $rootScope, products, categories, productsService) {

    var vm = this;

    vm.products = products;
    vm.categories = categories;

    vm.viewProduct = function (product) {
      productsService.productModal(product);
    }

    vm.viewMaster = function (product) {
      angular.forEach($rootScope.products, function (value) {
        if (product.masterid === value.id) {
          $rootScope.cpChangeSelection(value, product.id);
          productsService.productModal(value);
        }
      });
    }

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/pricelist/pricelist.js","/components/pricelist")