(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.frequentlyordered')
          .controller('FrequentlyOrderedCtrl', FrequentlyOrderedCtrl);

  FrequentlyOrderedCtrl.$inject = ['$log', '$scope', '$timeout', '$filter', '$rootScope', 'products', 'productsService', '$interval'];

  function FrequentlyOrderedCtrl($log, $scope, $timeout, $filter, $rootScope, products, productsService, $interval) {

    var vm = this;

    vm.products = [];

    function init() {
      console.log('init');
      if ($rootScope.frequent && $rootScope.products) {
        console.log('gogogo!');
        vm.products = [];
        angular.forEach($rootScope.frequent, function (frequentProduct) {
          angular.forEach($rootScope.products, function (product) {
            if (product.code === frequentProduct.code) {
              vm.products.push(product);
            }
          });
        });
      }
    }

    $scope.$watch('$root.frequent', function() {
      $timeout(function () {
        console.log('fired!');
        init();
      }, 1000)

    }, true);
    //vm.products = products;


    vm.addToOrder = function (product) {

      var item = angular.extend({}, product);
      item.name = $rootScope.pricelist[product.code].name;
      item.price = ($rootScope.pricelist[product.code].promotion !== false && $rootScope.pricelist[product.code].promotion.discount ? $rootScope.pricelist[product.code].promotion.price : $rootScope.pricelist[product.code].price);
      item.size = $rootScope.pricelist[product.code].uom;
      if ($rootScope.basket[product.code] !== undefined) {
        $rootScope.basket[product.code].qty += item.qty;

        if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
          $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
        }
      } else {
        $rootScope.basket[product.code] = item;

        if ($rootScope.pricelist[product.code].maximumorder && $rootScope.basket[product.code].qty > $rootScope.pricelist[product.code].maximumorder) {
          $rootScope.basket[product.code].qty = $rootScope.pricelist[product.code].maximumorder;
        }
      }
      product.qty = 1;
      product.adding = true;
      $timeout(function() {
        product.added = true;
        product.adding = false;
        $window.ga('send', 'event', 'Basket', 'click', 'Added ' + item.qty + ' of ' + product.code + ' ' + product.name + ' to basket');
      }, 3000);
    };

    vm.viewProduct = function (product) {
      productsService.productModal(product);
    }

    vm.viewMaster = function (product) {
      angular.forEach($rootScope.products, function (value) {
        if (product.masterid === value.id) {
          $rootScope.cpChangeSelection(value, product.id);
          productsService.productModal(value);
        }
      });
    }

    init();

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/frequentlyordered/frequentlyordered.js","/components/frequentlyordered")