(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {

  angular
    .module('ecog.core')
    //.constant('LAST_UPDATE', '20160603-1310')
    //.constant('ACTION_URL', 'http://localhost/jrwholesale/dist/actions/');
    //.constant('ACTION_URL', 'http://jrwholesale.ecognitiv.co.uk/actions/');
    //.constant('ACTION_URL', 'https://portal.jrpetproducts.com/actions/');
    .constant('ACTION_URL', JRCONSTANT.action);
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/core/constants.js","/core")