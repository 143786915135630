(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){
var app = angular.module('ecog');

app.controller('MainCtrl', ['$log', '$scope', '$rootScope', 'userCheckService', '$interval', '$location', '$modal', 'loginService', 'productsService', 'categoriesService', 'locker', '$templateCache', '$http', 'ACTION_URL', 'bsLoadingOverlayService', '$filter', '$cookies', function ($log, $scope, $rootScope, userCheckService, $interval, $location, $modal, loginService, productsService, categoriesService, locker, $templateCache, $http, ACTION_URL, bsLoadingOverlayService, $filter, $cookies) {

    $rootScope.basket = locker.get('basket') || {};
    $rootScope.basketTotal = 0.00;
    $rootScope.pricelist = {};
    $rootScope.viewmethod = 1;
    $rootScope.totals = {};
    $rootScope.hasMinimumRequiredBags = false;
    $rootScope.loadingMessage = undefined;
    $rootScope.shownDs2 = locker.get('shownDs2') || 0;
    $rootScope.shownPd = locker.get('shownPd') || 0;
    $rootScope.fetchedfrequentproducts = false;

    var discountItems = ['0PBZZ50', '0PCZZ50', '0PDZZ50', '0PKZZ50', '0PLZZ50', '0PRZZ50', '0PSZZ50', '0PVZZ50'];
    var discountItemsPh = ['0PHZZ10'];
    var wildBirdItems = ['BS15', 'PN25', 'SH20'];

    var allBags = ['0MS15','0MW12','0RA','BS15', 'PN25', 'SH20'];

    var nonapplicable = ['0BP12', '0BP15', '0BP20', '0BP75', '0BPN', '0BPP1', '0NT25', '0PB50', '0PD50', '0PC50', '0PK50', '0PL50', '0PR50', '0PS50', '0PV50', '0VAR1', '0VAR2', '0PHZZ10'];


    var pigsEarCode = '0IPE50LALALA';

    $scope.$watch(function () {
        return $rootScope.basket;
      }, calculateBasket, true);

    locker.bind($rootScope, 'basket');
    locker.bind($rootScope, 'shownDs2');
    locker.bind($rootScope, 'shownPd');

    getTotals();


    $rootScope.overlayStart = function () {

    };

    $rootScope.overlayEnd = function () {

    };

    // TODO: check the basket, names, prices, images, etc.

    function calculateBasket() {

        var qtyBfr = 4,
            qtyDiscount = 0,
            discountAmount = 0.00,
            discounttotal = 0.00,
            qtyBfrPh = 3,
            qtyDiscountPh = 0,
            discountAmountPh = 0.00,
            discounttotalPh = 0.00,
            pigsEarAmount = 0,
            pigsEarPrice = 0.00,
            pigsEarDiscountPrice = 12.50,
            newordertotal = 0.00,
            extradiscount = 0.00,
            extravatdiscount = 0.00;

        $rootScope.basketAmount = 0;
        $rootScope.wildBirdBags = 0;

        var nonBirdTotal = 0.00;

        $rootScope.hasMinimumRequiredBags = false;

        angular.forEach($rootScope.basket, function (value) {
          //$rootScope.basketTotal += value.price * value.qty;

          if ($rootScope.totals && $rootScope.totals.items && $rootScope.totals.items[value.code]) {
            var productTotals = $rootScope.totals.items[value.code];

            value.price = productTotals.priceper;
            value.unavailable = productTotals.unavailable;
          }
          $rootScope.basketAmount++;
          if ($rootScope.currentUser !== undefined && $rootScope.currentUser.type === 1) {

            if (wildBirdItems.indexOf(value.code) !== -1) {
              $rootScope.hasMinimumRequiredBags = true;
            }

            if (discountItems.indexOf(value.code) !== -1) {
              qtyDiscount += value.qty;

              if (discountAmount < value.price || discountAmount === 0.00) {
                discountAmount = value.price;
              }
            } else if (discountItemsPh.indexOf(value.code) !== -1) {
              qtyDiscountPh += value.qty;

              if (discountAmountPh < value.price || discountAmountPh === 0.00) {
                discountAmountPh = value.price;
              }
            } /*else if (value.code === pigsEarCode) {
              pigsEarAmount += value.qty;
              pigsEarPrice = value.price;
            }*/
            if (allBags.indexOf(value.code) === -1) {
              nonBirdTotal += value.price * value.qty;
            } else {
              $rootScope.wildBirdBags += value.qty;
            }

            if (nonapplicable.indexOf(value.code) === -1 && allBags.indexOf(value.code) === -1) {
              newordertotal += value.qty * value.price;
            }
          }
        });

        if (discountAmount > 0) {
          discounttotal = (Math.floor(qtyDiscount / (qtyBfr + 1))) * discountAmount;
        }

        if (discountAmountPh > 0) {
          discounttotalPh = (Math.floor(qtyDiscountPh / (qtyBfrPh + 1))) * discountAmountPh;
        }

        if ((newordertotal - discounttotal) > 100) {
          extradiscount = ((newordertotal - discounttotal) / 100) * 15;
        }

        //checkOutOfStocks();

    }

    $rootScope.getTotals = function () {
      getTotals();
    };

    function getTotals() {
      var items = [];

      angular.forEach($rootScope.basket, function (value) {
        items.push({ code: value.code, qty: value.qty, name: value.name });
      });

      $http.post(ACTION_URL + "baskettotals.php", {items:items, discountcode: undefined}).success(function(data, status) {
        if (data.message !== undefined && data.message === "SUCCESS") {
          $rootScope.totals = data.totals;
          calculateBasket();
        }
      });
    }

    function checkOutOfStocks() {
      angular.forEach($rootScope.products, function (value) {
        if ($rootScope.basket[value.code]) {
          if ($rootScope.pricelist[value.code].stocklevel === 0) {
            delete $rootScope.basket[value.code];
            $rootScope.getTotals();
          } else if ($rootScope.basket[value.code].qty > $rootScope.pricelist[value.code].stocklevel) {
            $rootScope.basket[value.code].qty = $rootScope.pricelist[value.code].stocklevel;
            $rootScope.getTotals();
          }
        }
      });

      // check items still exist...
      if ($rootScope.basket) {
        angular.forEach($rootScope.basket, function (value, key) {
          var productSearch = $filter('filter')($rootScope.products, { 'code' : key });
          if ((productSearch.length === 0 || productSearch.length > 1) || (!$rootScope.pricelist[key] || $rootScope.pricelist[key].stocklevel < 1)) {
            delete $rootScope.basket[key];
            $rootScope.getTotals();
          }
        });
      }

      getTotals();
    }



    // check logged in...
    userCheckService.check();

    $interval(function(){
      // loads user details and product prices
      userCheckService.check();
    }.bind(this), 10000);

    $rootScope.logout = function () {
      userCheckService.logout();
      $location.path('/login');
    };

    $rootScope.login = function () {
      loginService.loginModal();
    };

    $rootScope.whyapproval = function () {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/misc/whyapproval.html?update=' + FILEDATES['views/components/misc/whyapproval.html'] || '',
        controller: 'UserAgreementModalCtrl',
        controllerAs: 'vm',
        size: 'md'
      });

      modalInstance.result.then(function () {

      }, function () {

      });
    };

    $rootScope.categories = categoriesService.get();
    $rootScope.products = [];
    productsService.get()
      .then(function (value) {
        $rootScope.products = value;
        checkOutOfStocks();
      });
    $rootScope.upcomingPromotions = [];
    $rootScope.activePromotions = [];

    productsService.getPromotions()
      .then(function (data) {
        $rootScope.promotions = data;
      });

    $rootScope.goToCategory = function (id) {
      $location.path('/products/' + id);
    };

    $rootScope.goToAllProducts = function () {
      $location.path('/products/all');
    };

    $rootScope.orderLimit = 250.00;

    $rootScope.useragreement = useragreement;
    $rootScope.privacystatement = privacystatement;

    function useragreement() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/misc/useragreement.html?update=' + FILEDATES['views/components/misc/useragreement.html'] || '' + "&ek=2",
        controller: 'UserAgreementModalCtrl',
        controllerAs: 'vm',
        size: 'lg'
      });

      modalInstance.result.then(function () {

      }, function () {

      });
    }

    function privacystatement() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/misc/privacy.html?update=' + FILEDATES['views/components/misc/privacy.html'] || '',
        controller: 'PrivacyModalCtrl',
        controllerAs: 'vm',
        size: 'lg'
      });

      modalInstance.result.then(function () {

      }, function () {

      });
    }

    $rootScope.cpChangeSelection = cpChangeSelection;
    // container product functions...
    function cpChangeSelection(master, subid) {
      angular.forEach($rootScope.products, function (value, key) {
        if (value.id === subid) {
          master.selectedSub = subid;
          // populate master with sub item attributes.
          master.code = value.code;
          master.rrp = value.rrp;
          master.description = value.description;
          master.img = value.img;
          master.imageSrc = value.img;
          master.size = value.size;
          master.wholesaleuom = value.wholesaleuom;
          master.sizemultiplier = value.sizemultiplier;
          master.wholesalemultiplier = value.wholesalemultiplier;
          master.instock = value.instock;
          master.tradediscount = value.tradediscount;
          master.tradediscountdesc = value.tradediscountdesc;
          master.barcode = value.barcode;
          master.outerbarcode = value.outerbarcode;
          master.hasImage = value.hasImage;
          master.images = value.images;

        }
      });
    }




}]);



})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/app.logic.js","/")