(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.users')
    .factory('usersService', usersService);

  usersService.$inject = ['$log', '$modal', '$http', 'ACTION_URL', '$q'];

  function usersService ($log, $modal, $http, ACTION_URL, $q) {

    var service = {
      get: get,
      addresses: addresses,
      countries: countries,
    };

    return service;

    ////////////

    function get() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "user.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.user);
        }
      });

      return defer.promise;
    }

    function addresses() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "fetchaddresses.php").success(function(data, status) {

        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        } else {
          defer.resolve([]);
        }
      });

      return defer.promise;
    }

    function countries() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "fetchcountries.php").success(function(data, status) {

        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        } else {
          defer.resolve([]);
        }
      });

      return defer.promise;
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/users/users.service.js","/components/users")