(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function (){
  angular.module('ecog')
    .directive("autoFillable", autoFillable);

  autoFillable.$inject = ['$log', '$timeout'];

  function autoFillable($log, $timeout) {
    return {
      require: '?ngModel',
      restrict: 'A',
      link: function(scope, element, attrs, ngModel) {

        $timeout(function() {
          if (ngModel.$viewValue !== element.val()) {
            ngModel.$setViewValue(element.val());
          }
        }, 500);
      }
    };
  };

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/directives/autoFillable.js","/shared/directives")