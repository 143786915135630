(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.dashboard')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['$log']

    function getRoutes() {
        return [
            {
                url: '/pricelist',
                config: {
                    templateUrl: 'views/components/pricelist/view.html?update=' + FILEDATES['views/components/pricelist/view.html'] || '',
                    controller: 'PricelistCtrl',
                    controllerAs: 'vm',
                    title: 'Pricelist',
                    resolve: {
                      categories: function ($rootScope) {
                        return $rootScope.categories;
                      },
                      products: function ($rootScope) {
                        return $rootScope.products;
                      }
                    },
                    settings: {
                        requireAuth: true
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/pricelist/config.route.js","/components/pricelist")