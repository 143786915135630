(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
  angular
    .module('ecog', [
      // main modules...
      'ecog.core',
      // layout
      'ecog.layout',
      // component modules..
      'ecog.usercheck',
      'ecog.dashboard',
      'ecog.login',
      'ecog.search',
      'ecog.register',
      'ecog.contact',
      'ecog.profile',
      'ecog.account',
      'ecog.forgottenpassword',
      'ecog.password',
      'ecog.manageaddresses',
      'ecog.products',
      'ecog.promotions',
      'ecog.frequentlyordered',
      'ecog.pricelist',
      'ecog.categories',
      'ecog.users',
      'ecog.basket',
      'ecog.checkout',
      'ecog.barcodes',
      'ecog.cookies',
      'ecog.privacy',
    ])
    .run(runFunction);

    runFunction.$inject = ['$rootScope', '$location','$log','bsLoadingOverlayService', '$window'];

    function runFunction($rootScope, $location, $log, bsLoadingOverlayService, $window) {

      bsLoadingOverlayService.setGlobalConfig({
      		templateUrl: 'views/shared/layout/loading-overlay.html?update=' + FILEDATES['views/shared/layout/loading-overlay.html'] || '',
          delay: 500,
      	});

  	  $window.ga('create', 'UA-66342250-3', 'auto');

      $rootScope.$on('$locationChangeSuccess', function(event) {

        if (!$window.ga) {
          return;
        }

        $window.ga('send', 'pageview', { page: $location.path() });
      });

      $rootScope.$on('$routeChangeError', function(event, next, previous, error) {
        $log.info('Route change error on app module!');
        //$rootScope.overlayEnd();
        if (error === "AUTH_REQUIRED") {
          $location.path('/login');
        }
      });



    }

  // core modules

  require('./core/core.module.js');
  require('./core/constants.js');
  require('./core/config.js');

  // router
  require('./shared/router/router.module.js');
  require('./shared/router/routerhelper.js');

  // directives
  require('./shared/directives/counter.js');
  require('./shared/directives/compareTo.js');
  require('./shared/directives/loadingScreen.js');
  require('./shared/directives/numbersOnly.js');
  require('./shared/directives/autoFillable.js');

  // filters
  require('./shared/filters/staggeredClassFilter.js');
  require('./shared/filters/categoryFilter.js');
  require('./shared/filters/tradingforFilter.js');
  require('./shared/filters/ordersFilter.js');
  require('./shared/filters/productsFilter.js');
  require('./shared/filters/promotionFilter.js');
  require('./shared/filters/activePromotions.js');
  require('./shared/filters/topSeller.js');
  require('./shared/filters/specificProductsFilter.js');
  require('./shared/filters/toArrayFilter.js');

  // layout
  require('./shared/layout/layout.module.js');
  require('./shared/layout/sidebar.js');
  require('./shared/layout/navbar.js');
  require('./shared/layout/basket.js');
  require('./shared/layout/loading.service.js');

  require('./components/usercheck/usercheck.module.js');
  require('./components/usercheck/usercheck.service.js');

  // dashboard
  require('./components/dashboard/dashboard.module.js');
  require('./components/dashboard/config.route.js');
  require('./components/dashboard/dashboard.js');
  require('./components/dashboard/modal/message.js');
  require('./components/dashboard/modal/palletdeals.js');

  // barcodes
  require('./components/barcodes/barcodes.module.js');
  require('./components/barcodes/config.route.js');
  require('./components/barcodes/barcodes.js');

  // dashboard
  require('./components/basket/basket.module.js');
  require('./components/basket/config.route.js');
  require('./components/basket/basket.js');

  // misc
  require('./components/misc/useragreement.js');
  require('./components/misc/privacy.js');
  require('./components/misc/settings.service.js');

  // login
  require('./components/login/login.module.js');
  require('./components/login/config.route.js');
  require('./components/login/login.service.js');
  require('./components/login/login.js');
  require('./components/login/modal/login.js');

  // search
  require('./components/search/search.module.js');
  require('./components/search/search.service.js');
  require('./components/search/search.js');

  // register
  require('./components/register/register.module.js');
  require('./components/register/config.route.js');
  require('./components/register/register.js');

  // register
  require('./components/contact/contact.module.js');
  require('./components/contact/config.route.js');
  require('./components/contact/contact.js');

  // cookies
  require('./components/cookies/cookies.module.js');
  require('./components/cookies/config.route.js');
  require('./components/cookies/cookies.js');

  // privacy
  require('./components/privacy/privacy.module.js');
  require('./components/privacy/config.route.js');
  require('./components/privacy/privacy.js');

  // profile
  require('./components/profile/profile.module.js');
  require('./components/profile/config.route.js');
  require('./components/profile/profile.js');

  // forgotten password
  require('./components/forgottenpassword/forgottenpassword.module.js');
  require('./components/forgottenpassword/config.route.js');
  require('./components/forgottenpassword/forgottenpassword.js');

  // account
  require('./components/account/account.module.js');
  require('./components/account/config.route.js');
  require('./components/account/account.service.js');
  require('./components/account/account.js');
  require('./components/account/order.js');
  // password
  require('./components/password/password.module.js');
  require('./components/password/config.route.js');
  require('./components/password/password.js');

  // password
  require('./components/manageaddresses/manageaddresses.module.js');
  require('./components/manageaddresses/config.route.js');
  require('./components/manageaddresses/manageaddresses.js');

  // checkout
  require('./components/checkout/checkout.module.js');
  require('./components/checkout/config.route.js');
  require('./components/checkout/checkout.js');
  require('./components/checkout/checkoutreturn.js');

  // products
  require('./components/products/products.module.js');
  require('./components/products/products.service.js');
  require('./components/products/config.route.js');
  require('./components/products/products.js');
  require('./components/products/modal/product.js');

  // products
  require('./components/promotions/promotions.module.js');
  require('./components/promotions/config.route.js');
  require('./components/promotions/promotions.js');

  // frequentlyordered
  require('./components/frequentlyordered/frequentlyordered.module.js');
  require('./components/frequentlyordered/config.route.js');
  require('./components/frequentlyordered/frequentlyordered.js');


  // pricelist
  require('./components/pricelist/pricelist.module.js');
  require('./components/pricelist/config.route.js');
  require('./components/pricelist/pricelist.js');

  // users
  require('./components/users/users.module.js');
  require('./components/users/users.service.js');

  // categories
  require('./components/categories/categories.module.js');
  require('./components/categories/categories.service.js');

  // misc
  require('./app.logic.js');
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_d96719f9.js","/")