(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.basket')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['categoriesService', '$log']

    function getRoutes(categoriesService) {
        return [
            {
                url: '/basket',
                config: {
                    templateUrl: 'views/components/basket/view.html?update=' + FILEDATES['views/components/basket/view.html'] || '',
                    controller: 'BasketCtrl',
                    controllerAs: 'vm',
                    title: 'Order',
                    resolve: {}
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/basket/config.route.js","/components/basket")