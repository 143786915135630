(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.products')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['categoriesService', '$route', '$rootScope']

    function getRoutes(categoriesService, $route, $rootScope) {
        return [
            {
                url: '/products',
                config: {
                    templateUrl: 'views/components/products/view.html?update=' + FILEDATES['views/components/products/view.html'] || '',
                    controller: 'ProductsCtrl',
                    controllerAs: 'vm',
                    title: 'Products',
                    resolve: {
                      categories: function ($rootScope) {
                        return $rootScope.categories;
                      },
                      products: function ($rootScope) {
                        return $rootScope.products;
                      },
                      category: function () {
                        return undefined;
                      },
                      search: function () {
                        return false;
                      },
                      searchtext: function () {
                        return undefined;
                      },
                      promotion: function () {
                        return undefined;
                      },
                      giveaway: function () {
                        return undefined;
                      }
                    },
                    settings: {
                        nav: 2,
                        content: '<i class="icons icon-grid"></i>Products',
                        requireAuth: false,
                        subitem: {
                          name: 'category'
                        }
                    }
                }
            },
            {
                url: '/products/:categoryId',
                config: {
                    templateUrl: 'views/components/products/view.html?update=' + FILEDATES['views/components/products/view.html'] || '',
                    controller: 'ProductsCtrl',
                    controllerAs: 'vm',
                    title: 'Products',
                    resolve: {
                      categories: function ($rootScope) {
                        return $rootScope.categories;
                      },
                      products: function ($rootScope) {
                        return $rootScope.products;
                      },
                      category: function (categoriesService, $route) {
                        if ($route.current.params.categoryId !== 'all') {
                          return categoriesService.getById($route.current.params.categoryId);
                        } else {
                          return {
                            id: 0, name: 'All Products'
                          };
                        }
                      },
                      search: function () {
                        return false;
                      },
                      searchtext: function () {
                        return undefined;
                      },
                      promotion: function () {
                        return undefined;
                      },
                      giveaway: function () {
                        return undefined;
                      }
                    }
                }
            },
            {
                url: '/search/:searchtext',
                config: {
                    templateUrl: 'views/components/products/view.html?update=' + FILEDATES['views/components/products/view.html'] || '',
                    controller: 'ProductsCtrl',
                    controllerAs: 'vm',
                    title: 'Product Search',
                    resolve: {
                      categories: function ($rootScope) {
                        return $rootScope.categories;
                      },
                      products: function ($rootScope) {
                        return $rootScope.products;
                      },
                      category: function (categoriesService, $route) {
                        return undefined;
                      },
                      search: function () {
                        return true;
                      },
                      searchtext: function ($route) {
                        return $route.current.params.searchtext;
                      },
                      promotion: function () {
                        return undefined;
                      },
                      giveaway: function () {
                        return undefined;
                      }
                    }
                }
            },
            {
                url: '/promotions/:promotionId',
                config: {
                    templateUrl: 'views/components/products/view.html?update=' + FILEDATES['views/components/products/view.html'] || '',
                    controller: 'ProductsCtrl',
                    controllerAs: 'vm',
                    title: 'Product Search',
                    resolve: {
                      categories: function ($rootScope) {
                        return $rootScope.categories;
                      },
                      products: function ($rootScope) {
                        return $rootScope.products;
                      },
                      category: function (categoriesService, $route) {
                        return undefined;
                      },
                      search: function () {
                        return false;
                      },
                      searchtext: function ($route) {
                        return undefined;
                      },
                      promotion: function ($route) {
                        return $route.current.params.promotionId;
                      },
                      giveaway: function () {
                        return undefined;
                      }
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/products/config.route.js","/components/products")