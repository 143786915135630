(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.account')
    .factory('accountService', accountService);

  accountService.$inject = ['$log', '$modal', '$q', '$http', 'ACTION_URL'];

  function accountService ($log, $modal, $q, $http, ACTION_URL) {

    var service = {
      getOrders: getOrders,
      getOrderById: getOrderById
    };

    return service;

    ////////////

    function getOrders(recent) {

      var recent = recent || false;

      var defer = $q.defer();

      $http.post(ACTION_URL + "orders.php?recent=" + recent).success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

    function getOrderById(id) {

      var defer = $q.defer();

      $http.post(ACTION_URL + "order.php?id=" + id).success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.item);
        }
      });

      return defer.promise;
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/account/account.service.js","/components/account")