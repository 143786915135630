(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('promotionFilter', promotionFilter);

  promotionFilter.$inject = ['$log'];

  function promotionFilter($log) {

    return function(items, promotionId, priceItems) {
      var out = [];

      if (angular.isArray(items) && angular.isObject(priceItems) && promotionId !== undefined) {


        items.forEach(function(item) {

          item.pagerank = 0;

          if (priceItems[item.code] !== undefined && priceItems[item.code].promotion !== false && priceItems[item.code].promotion.promotionid == promotionId && !item.isMaster) {
            item.pagerank = priceItems[item.code].promotion.pagerank;
            out.push(item);
          }
        });

      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/promotionFilter.js","/shared/filters")