(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.dashboard')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['categoriesService', '$log']

    function getRoutes(categoriesService) {
        return [
            {
                url: '/',
                config: {
                    templateUrl: 'views/components/dashboard/dashboard2.html?update=' + FILEDATES['views/components/dashboard/dashboard2.html'] || '',
                    controller: 'DashboardCtrl',
                    controllerAs: 'vm',
                    title: 'Dashboard',
                    resolve: {
                      categories: function (categoriesService) {
                        return categoriesService.get();
                      }
                    },
                    settings: {
                        nav: 1,
                        content: '<i class="icons icon-home"></i>Home',
                        requireAuth: false
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/dashboard/config.route.js","/components/dashboard")