(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.account')
          .controller('OrderCtrl', OrderCtrl);

  OrderCtrl.$inject = ['$scope', '$timeout', '$log', '$route', '$rootScope', 'order', '$http', 'ACTION_URL'];

  function OrderCtrl($scope, $timeout, $log, $route, $rootScope, order, $http, ACTION_URL) {

    var vm = this;

    // vars
    vm.order = order;
    vm.paymenttypes = [ {id:1,name:'Pay-Pal'},{id:2,name:'Direct Bank Transfer'},{id:3,name:'On Account'}];
    vm.paymentstatus = [ {id:1,name:'Awaiting Transaction Reconcile'},{id:2,name:'Awaiting Payment'},{id:3,name:'Awaiting Processing'},{id:4,name:'Processing'},{id:5,name:'Partially Dispatched'},{id:6,name:'Dispatched'},{id:7,name:'Cancelled'}];

    angular.forEach(vm.order.lines, function (value, key) {
      if (value.datecomplete !== null) {
        value.datecomplete = new Date(value.datecomplete * 1000);
      }
    });

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/account/order.js","/components/account")