(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('categoryFilter', categoryFilter);

  categoryFilter.$inject = ['$log'];

  function categoryFilter($log) {

    return function(items, categoryId) {
      var out = [];

      if (angular.isArray(items) && categoryId !== undefined && categoryId !== 0) {
        items.forEach(function(item) {

          item.pagerank = 0;

          if (item.category[categoryId] !== undefined) {

            item.pagerank = item.category[categoryId].pagerank;

            //console.log(item);
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/categoryFilter.js","/shared/filters")