(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'ContactCtrl';

    angular.module('ecog.contact')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'loadingService', contact]);

    function contact($log, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, loadingService) {

        var vm = this;

        vm.isSubmitting = false;
        vm.success = false;
        vm.errors = [];
        vm.mask = "0999999999?9";
        vm.user = {};

        vm.submit = submit;

        init();

        function init() {
          // check if user is logged in, then pre-fill contact fields...
          if ($rootScope.currentUser !== undefined) {
            vm.user.name = $rootScope.currentUser.name;
            vm.user.email = $rootScope.currentUser.email;
            vm.user.businessname = $rootScope.currentUser.businessname;
          }

        }

        function submit() {
          loadingService.start("contactOverlay", "Submitting");
          vm.isSubmitting = true;

          $http.post(ACTION_URL + "contact.php", vm.user).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              vm.success = true;
            } else if (data.data.errors) {

              vm.errors = data.data.errors;
              if (data.data.errorstage !== undefined && data.data.errorstage !== null) {
                vm.stage = data.data.errorstage;
              }
            } else {
              vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
            }

            vm.isSubmitting = false;
          }, function (error) {

            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
            loadingService.stop("contactOverlay");
          });
        }
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/contact/contact.js","/components/contact")