(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'CookiesCtrl';

    angular.module('ecog.cookies')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'loadingService', cookies]);

    function cookies($log, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, loadingService) {

        var vm = this;

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/cookies/cookies.js","/components/cookies")