(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'ForgottenPasswordCtrl';

    angular.module('ecog.forgottenpassword')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'loadingService', password]);

    function password($log, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, loadingService) {

        var vm = this;

        vm.isSubmitting = false;
        vm.errors = [];
        vm.stage = 1;
        vm.user = {};

        vm.submit = submit;

        function submit() {

          vm.isSubmitting = true;

          loadingService.start("forgottenOverlay", "submitting");

          $http.post(ACTION_URL + "forgottenpassword.php", vm.user).then(function(data, status) {
            vm.message = data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              vm.stage = 2;
            } else if (data.data.errors) {
              vm.errors = data.data.errors;
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }
            vm.isSubmitting = false;
          }, function (error) {

            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
            loadingService.stop("forgottenOverlay");
          });
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/forgottenpassword/forgottenpassword.js","/components/forgottenpassword")