(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.login')
    .factory('loginService', loginService);

  loginService.$inject = ['$log', '$modal'];

  function loginService ($log, $modal) {

    var service = {
      loginModal: loginModal
    };

    return service;

    ////////////

    function loginModal() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/login/modal/login.html?update=' + FILEDATES['views/components/login/modal/login.html'] || '',
        controller: 'LoginModalCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {}
      });

      modalInstance.result.then(function (item) {

      }, function () {

      });
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/login/login.service.js","/components/login")