(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.layout')
        .controller('Basket', Basket);

    Basket.$inject = ['$route', 'routehelper', '$rootScope', '$log', '$location'];

    function Basket($route, routehelper, $rootScope, $log, $location) {
      var vm = this;

      vm.removeItem = function (code) {
        delete $rootScope.basket[code];
        $rootScope.getTotals();
      };

      $rootScope.goCheckout = function () {
        $location.path('/checkout');
        $rootScope.basketOpen = false;
      }
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/layout/basket.js","/shared/layout")