(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.basket')
          .controller('BasketCtrl', BasketCtrl);

  BasketCtrl.$inject = ['$scope', '$timeout', '$rootScope', '$log', '$interval'];

  function BasketCtrl($scope, $timeout, $rootScope, $log, $interval) {

    var vm = this;

    vm.timer = 5;
    vm.timerrunning = false;
    vm.updating = true;
    vm.watchers = {};

    var timer;

    angular.forEach($rootScope.basket, function (value, key) {
      vm.watchers[key] = $scope.$watch(
        function () {
          return $rootScope.basket[key].qty;
        },
        function () {
          if (vm.timerunning) {
            vm.timer = 1;
          } else {
            vm.timerunning = true;
            timer = $interval(function () {
              vm.timer--;
              if (vm.timer < 0) {
                $rootScope.getTotals();
                vm.timer = 1;
                vm.timerunning = false;
                $scope.removeTimer();
              }
            }, 1000);
          }
        }
      );
    });

    $scope.removeTimer = function () {
      if (angular.isDefined(timer)) {
        $interval.cancel(timer);
        timer = undefined;
      }
    }

    $scope.$on('$destroy', function() {
      // Make sure that the interval is destroyed too
      $scope.removeTimer();
    });

    vm.removeItem = function (code) {
      vm.watchers[code]();
      delete $rootScope.basket[code];
      $rootScope.getTotals();
    };

    init();

    function init() {
      $rootScope.getTotals();
    }

  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/basket/basket.js","/components/basket")