(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'ProfileCtrl';

    angular.module('ecog.profile')
        .controller(controllerId, ['$log', '$rootScope', 'user', '$modal', '$http', 'ACTION_URL', 'usersService', profile]);

    function profile($log, $rootScope, user, $modal, $http, ACTION_URL, usersService) {

        var vm = this;

        vm.isSubmitting = false;
        vm.success = false;
        vm.errors = [];

        vm.countries = [];

        vm.user = {
          firstname: user.firstname,
          lastname: user.lastname,
          businessname: user.businessname || '',
          businesstype: user.businesstype.toString(),
          tradingfor: user.tradingfor.toString(),
          address1: user.address1 || '',
          address2: user.address2 || '',
          city: user.city || '',
          county: user.county || '',
          postcode: user.postcode,
          country: user.country || '',
          landline: user.landline || '',
          mobile: user.mobile || '',
          vatnumber: user.vatnumber || '',
        };

        vm.submit = submit;

        function submit() {

          $http.post(ACTION_URL + "profile.php", vm.user).success(function(data, status) {
            if (data.message !== undefined && data.message === "SUCCESS") {
              vm.success = true;
            } else {
              vm.errors = data.errors;
            }
          });
        }

        init();

        function init() {
          vm.loadingCountries = true;
          usersService.countries().then(function (data) {
            vm.countries = data;
            vm.loadingCountries = false;
          });
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/profile/profile.js","/components/profile")