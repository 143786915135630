(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('ordersFilter', ordersFilter);

  ordersFilter.$inject = ['$log'];

  function ordersFilter($log) {

    return function(items, previous) {
      var out = [];

      if (angular.isArray(items)) {
        items.forEach(function(item) {
          if (item.status !== undefined) {
            if (previous && item.status > 5) {
              out.push(item);
            } else if (!previous && item.status < 6) {
              out.push(item);
            }
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/ordersFilter.js","/shared/filters")