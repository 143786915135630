(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'PrivacyModalCtrl';

    angular.module('ecog')
        .controller(controllerId, ['$log', '$modalInstance', privacy]);

    function privacy($log, $modalInstance) {
        var vm = this;

        vm.accept = accept;
        vm.close = close;

        function accept() {
          $modalInstance.close();
        }

        function close() {
          $modalInstance.dismiss();
        }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/misc/privacy.js","/components/misc")