(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.products')
    .factory('productsService', productsService);

  productsService.$inject = ['$log', '$modal', '$http', 'ACTION_URL', '$q'];

  function productsService ($log, $modal, $http, ACTION_URL, $q) {

    var service = {
      get: get,
      query: query,
      getPromotion: getPromotion,
      getPromotions: getPromotions,
      productModal: productModal,
      getFrequent: getFrequent,
    };

    return service;

    ////////////

    function get() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "products.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

    function query(query) {
      var defer = $q.defer();

      $http.post(ACTION_URL + "products.php?query=" + query).success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

    function getPromotion(id) {

      var defer = $q.defer();

      $http.post(ACTION_URL + "promotion.php?id=" + id).success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.item);
        }
      });

      return defer.promise;
    }

    function getPromotions() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "promotions.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

    function productModal(product) {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/products/modal/product.html?update=' + FILEDATES['views/components/products/modal/product.html'] || '',
        controller: 'ProductModalCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
          item: function () {
            return product;
          }
        }
      });

      modalInstance.result.then(function (item) {

      }, function () {

      });
    }

    function getFrequent() {
      var defer = $q.defer();

      $http.post(ACTION_URL + "frequentlyordered.php").success(function(data, status) {
        if (data.message !== undefined && data.message !== "ERROR") {
          defer.resolve(data.items);
        }
      });

      return defer.promise;
    }

  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/products/products.service.js","/components/products")