(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.dashboard')
          .controller('DashboardCtrl', DashboardCtrl);

  DashboardCtrl.$inject = ['$modal', '$scope', '$location', '$timeout', '$rootScope', 'categories', 'accountService', '$http', 'ACTION_URL', 'productsService', '$log', '$cookies', '$interval'];

  function DashboardCtrl($modal, $scope, $location, $timeout, $rootScope, categories, accountService, $http, ACTION_URL, productsService, $log, $cookies, $interval) {

    var vm = this;

    vm.categories = categories;
    vm.orders = [];
    vm.paymenttypes = [ {id:1,name:'Pay-Pal'},{id:2,name:'Direct Bank Transfer'},{id:3,name:'On Account'},{id:4,name:'Card Payment (via Barclays Card)'}];
    vm.paymentstatus = [ {id:1,name:'Awaiting Transaction Reconcile'},{id:2,name:'Awaiting Payment'},{id:3,name:'Awaiting Processing'},{id:4,name:'Processing'},{id:5,name:'Partially Dispatched'},{id:6,name:'Dispatched'},{id:7,name:'Cancelled'}];
    vm.topsellers = [];


    // functions...
    vm.view = view;
    vm.viewOrder = viewOrder;
    vm.viewProduct = viewProduct;
    vm.viewMaster = viewMaster;
    vm.frontpage = '';
    vm.clickBlackFriday = clickBlackFriday;

    vm.tsFrom = 0;
    vm.tsTo = 5;
    vm.frFrom = 0;
    vm.frTo = 5;

    if ($rootScope.currentUser !== undefined) {
      accountService.getOrders(true).then(
        function (data) {
          angular.forEach(data, function (value) {
            value.dateplaced = new Date(value.dateplaced);
          });
          vm.orders = data;
        }
      );
    }
    //$timeout(palletPopup, 3000);
    $http.post(ACTION_URL + "frontpage.php", {}).success(function(data, status) {
      if (data.message === 'SUCCESS') {
        vm.frontpage = data.result;

        if (vm.frontpage) {
          $timeout(dashboardPopup, 2000);
        }
      }
    });
    vm.clickPalletDeals = clickPalletDeals;

    function clickPalletDeals() {
      $location.url('/products/' + PALLETCATEGORY);
      $modalInstance.dismiss();
    }

    $http.post(ACTION_URL + "topsellers.php", {}).success(function(data, status) {
      if (data.message === 'SUCCESS') {
        vm.topsellers = data.items;
      }
    });

    vm.frequent = [];

    function getFrequent() {
      productsService.getFrequent().then(function (frequent) {
        $timeout(function () {
          if (frequent && $rootScope.products) {
            var i = 1;
            angular.forEach(frequent, function (frequentProduct) {
              angular.forEach($rootScope.products, function (product) {
                if (product.code == frequentProduct.code && $rootScope.pricelist[product.code].stocklevel > 0 && i !== 11) {
                  var newproduct = angular.extend({}, product);
                  newproduct.frequent = i;
                  vm.frequent.push(newproduct);
                  i++;
                }
              });
            });
          }
        }, 1000);
      });
    }
    //

    getFrequent();

    function view(string) {
      $location.path('/' + string);
    }

    function viewOrder(id) {
      $location.path('/order/' + id);
    }

    function viewProduct(product) {
      productsService.productModal(product);
    }

    function viewMaster(product) {
      angular.forEach($rootScope.products, function (value) {
        if (product.masterid === value.id) {
          $rootScope.cpChangeSelection(value, product.id);
          productsService.productModal(value);
        }
      });
    }

    //

    function dashboardPopup() {
      if (!$rootScope.shownDs2 || $rootScope.shownDs2 === 0 || $rootScope.shownDs2 === "0") {
        if ($rootScope.currentUser && $rootScope.currentUser.type == 1 && $rootScope.currentUser.nopromotions == 0) {
          dashBoardMessage();
          console.log('yel');
        } else {
          console.log('nol');
        }
      } else {
        console.log('nol');
        console.log($rootScope.shownDs2);
      }
    }

    function dashBoardMessage() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/dashboard/modal/message.html?update=' + FILEDATES['views/components/dashboard/modal/message.html'] || '',
        controller: 'DashboardMessageModalCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
          url: function () {
            return vm.frontpage;
          }
        }
      });

      modalInstance.result.then(function () {

      }, function () {

      });
    }

    function clickBlackFriday() {
      if ($rootScope.currentUser && $rootScope.currentUser.type == 1) {
        $location.path('/promotions');
      } else {
        $location.path('/register');
      }
    }

    function palletPopup() {
      if (!$rootScope.shownPd || $rootScope.shownPd === 0 || $rootScope.shownPd === "0") {
        if ($rootScope.currentUser && ($rootScope.currentUser.type == 1 && $rootScope.currentUser.nopromotions == 0) && ['1','2','3','4'].indexOf($rootScope.currentUser.deliveryclass.toString()) !== -1) {
          palletMessage();
          console.log('yel');
        } else {
          console.log('nol');
        }
      }
    }

    function palletMessage() {
      var modalInstance = $modal.open({
        animation: true,
        templateUrl: 'views/components/dashboard/modal/palletdeals.html?update=' + FILEDATES['views/components/dashboard/modal/palletdeals.html'] || '',
        controller: 'PalletDealsModalCtrl',
        controllerAs: 'vm',
        size: 'md',
        resolve: {
        }
      });

      modalInstance.result.then(function () {

      }, function () {

      });
    }




  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/dashboard/dashboard.js","/components/dashboard")