(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('productsFilter', productsFilter);

  productsFilter.$inject = ['$log'];

  function productsFilter($log) {

    return function(items, search) {
      var out = [];

      if (angular.isArray(items) && search.toString().length > 0) {
        items.forEach(function(item) {
          if ((item.name && item.code) && (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.code.toLowerCase().indexOf(search.toLowerCase()) !== -1)) {
            out.push(item);
          }
        });
      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/productsFilter.js","/shared/filters")