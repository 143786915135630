(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {

  angular
          .module('ecog.promotions')
          .controller('PromotionsCtrl', PromotionsCtrl);

  PromotionsCtrl.$inject = ['$rootScope','productsService', '$timeout'];

  function PromotionsCtrl($rootScope, productsService, $timeout) {

    var vm = this;
    vm.loading = true;
    vm.promotions = [];

    $timeout(function() {
      vm.loading = false;
      vm.promotions = angular.extend([], $rootScope.promotions);
    }, 3000);


    vm.viewProduct = viewProduct;
    vm.viewMaster = viewMaster;

    function viewProduct(product) {
      productsService.productModal(product);
    }

    function viewMaster(product) {
      angular.forEach($rootScope.products, function (value) {
        if (product.masterid === value.id) {
          $rootScope.cpChangeSelection(value, product.id);
          productsService.productModal(value);
        }
      });
    }
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/promotions/promotions.js","/components/promotions")