(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.layout')
    .factory('loadingService', loadingService);

  loadingService.$inject = ['$log', '$modal', '$http', 'ACTION_URL', '$q', 'bsLoadingOverlayService', '$rootScope'];

  function loadingService ($log, $modal, $http, ACTION_URL, $q, bsLoadingOverlayService, $rootScope) {

    var service = {
      start: start,
      stop: stop,
    };

    function start(id, message) {
      $rootScope.loadingMessage = message || 'Loading';
      bsLoadingOverlayService.start({
        referenceId: id
      });
    }

    function stop(id) {
      bsLoadingOverlayService.stop({
        referenceId: id
      });
    }

    return service;

    ////////////


  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/layout/loading.service.js","/shared/layout")