(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.barcodes')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = ['productsService', 'categoriesService', '$log']

    function getRoutes(productsService, categoriesService, $log) {
        return [
            {
                url: '/barcodes',
                config: {
                    templateUrl: 'views/components/barcodes/barcodes.html?update=' + FILEDATES['views/components/barcodes/barcodes.html'] || '',
                    controller: 'BarcodesCtrl',
                    controllerAs: 'vm',
                    title: 'Product Barcodes',
                    resolve: {
                      categories: function (categoriesService) {
                        return categoriesService.get();
                      },
                      products: function (productsService) {
                        return productsService.get();
                      },
                    },
                    settings: {
                        requireAuth: true
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/barcodes/config.route.js","/components/barcodes")