(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular.module('ecog')
    .filter('topSeller', topSeller);

  topSeller.$inject = ['$log'];

  function topSeller($log) {

    return function(items) {
      var out = [];

      if (angular.isArray(items)) {

        var topSeller = 99999,
            itemId = undefined;

        items.forEach(function(item, key) {
            if (item.topseller && item.topseller < topSeller) {
              itemId = key;
              topSeller = item.topseller;
            }
        });

        if (itemId !== undefined) {
          items[itemId].bestseller = true;
        }

        out = items;

      } else {
        // Let the output be the input untouched
        out = items;
      }

      return out;
    };
  }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/shared/filters/topSeller.js","/shared/filters")