(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'LoginModalCtrl';

    angular.module('ecog.login')
        .controller(controllerId, ['$log', '$rootScope', '$location', '$cookies', 'ACTION_URL', '$http', '$modalInstance', 'loadingService', login]);

    function login($log, $rootScope, $location, $cookies, ACTION_URL, $http, $modalInstance, loadingService) {
        var vm = this;

        vm.isSubmitting = false;
        vm.success = false;
        vm.errors = [];
        vm.submit = submit;
        vm.close = close;
        vm.goregister = goregister;
        vm.user = {
          email: '',
          password: ''
        }

        if ($cookies.get('email')) {
          vm.user.email = $cookies.get('email');
          vm.rememberme = true;
        }

        function submit() {
          vm.isSubmitting = true;
          loadingService.start("signinModalOverlay", "Signing in");

          $http.post(ACTION_URL + "login.php", vm.user).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              $rootScope.currentUser = data.data.userObj;
              $rootScope.pricelist = data.data.priceObj;
              if (vm.rememberme === true) {
                $cookies.put('email', vm.user.email);
              } else {
                $cookies.remove('email');
              }
              vm.isSubmitting = false;
              vm.errors = [];
              vm.success = true;
            } else if (data.data.errors) {
              vm.isSubmitting = false;
              vm.errors = data.data.errors;
            } else {
              vm.isSubmitting = false;
              vm.errors = [ 'There was an unforseen error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
            }
          }, function (error) {
            vm.isSubmitting = false;
            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            loadingService.stop("signinModalOverlay");
          });
        }

        function close() {
          $modalInstance.dismiss();
        }

        function goregister() {
          $modalInstance.dismiss();
          $location.path('/register');
        }
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/login/modal/login.js","/components/login/modal")