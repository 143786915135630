(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function() {
    'use strict';

    angular
        .module('ecog.promotions')
        .run(appRun);

    // appRun.$inject = ['routehelper']

    /* @ngInject */
    function appRun(routehelper) {
        routehelper.configureRoutes(getRoutes());
    }

    getRoutes.$inject = []

    function getRoutes() {
        return [
            {
                url: '/promotions',
                config: {
                    templateUrl: 'views/components/promotions/promotions.html?update=' + FILEDATES['views/components/promotions/promotions.html'] || '',
                    controller: 'PromotionsCtrl',
                    controllerAs: 'vm',
                    title: 'Promotions',
                    resolve: {},
                    settings: {
                        requireAuth: false
                    }
                }
            }
        ];
    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/promotions/config.route.js","/components/promotions")